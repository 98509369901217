import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import {Link} from 'gatsby'

const ThankYouPage = () => (
  <Layout>
    <SEO title="Obrigado pela mensagem" />
    <div className="thank-you-wrapper">
        <p style={{textAlign: "center", fontSize: 50, marginBottom: 0}}>Obrigado pela mensagem!</p>
        <p style={{textAlign: "center", fontSize: 35, opacity: '0.8'}}>Entraremos em contato em breve.</p>
        <button className="rn-button" style={{margin: "auto"}}>
            <span className="category">
                <Link to={`/`}>
                    Voltar para o site
                </Link>
            </span>
        </button>
    </div>
  </Layout>
)
export default ThankYouPage;